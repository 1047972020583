<template>
  <div class="fun">
    <div class="popular" v-if="obj.popularFlag">当前人气值: {{ popular }}</div>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        class="scroll"
        v-if="flag && mobile"
        :style="viewList.length > 8 ? 'justify-content: flex-start' : ''"
      >
        <div class="viewList" style="min-width: auto">
          <div
            :class="['list', value == index ? 'color' : '']"
            v-for="(item, index) in viewList"
            :key="index"
            @click="toView(item, index)"
          >
            <img :src="item.banner" />
            <div class="font">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="scroll" v-if="flag && !mobile">
        <img src="../assets/img/add.png" class="log" @click="left" />
        <div class="pcscroll">
          <div class="viewList" :style="'transform:' + 'translateX(' + scrollX + 'px)'">
            <div
              :class="['list', value == index ? 'color' : '']"
              v-for="(item, index) in viewList"
              :key="index"
              @click="toView(item, index)"
            >
              <img :src="item.banner" />
              <div class="font">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <img src="../assets/img/reduce.png" class="log" @click="right" />
      </div>
    </transition>

    <!-- <img
      src="../assets/down.png"
      :class="['down', !flag ? 'trans' : '']"
      @click="flag = !flag"
    />
    <div class="niaokan" @click="see">
      <img src="../assets/small.png" />
      三维鸟瞰
    </div> -->

    <!-- <div class="niaokan roam" @click="roam" id="roam">
      <img src="../assets/roam.png" />
      {{ !roomFlag ? "开始漫游" : "停止漫游" }}
    </div> -->
    <!-- 下方功能 -->
    <div
      class="functionB"
      :style="
        uitype == 1 || uitype == 3
          ? 'background-color:rgba(45, 45, 45, 0.5);color:white'
          : ''
      "
    >
      <div class="item" @click="flag = !flag">
        <img :src="uiList[uitype][8]" style="width: 30px" />
        <p :style="uitype == 1 || uitype == 3 ? 'color:white' : ''">场景选择</p>
      </div>
      <div
        class="item"
        @click="roam"
        v-if="this.obj.airFlag && this.obj.airFlag == true && vip > 0"
      >
        <img :src="uiList[uitype][9]" style="width: 30px" />
        <p :style="uitype == 1 || uitype == 3 ? 'color:white' : ''">三维鸟瞰</p>
      </div>
    </div>
    <!-- 点赞 -->
    <div class="functionBottom">
      <img :src="uiList[uitype][5]" v-if="commentFlag" @click="openComment" />
      <div style="position: relative">
        <img :src="uiList[uitype][6]" @click="addNum" v-if="likeFlag && !addFlag" />
        <img :src="uiList[uitype][11]" v-if="likeFlag && addFlag" />
        <p v-if="likeFlag">{{ thumbs ? thumbs : 0 }}</p>
      </div>

      <img :src="uiList[uitype][7]" @click="shareFlag = true" />
    </div>
    <!-- 顶部功能 -->
    <div class="functionTop">
      <img :src="uiList[uitype][0]" @click="full" v-if="id != 1975" />
      <img
        :src="voiceFlag ? uiList[uitype][1] : uiList[uitype][10]"
        @click="voiceFlag = !voiceFlag"
      />

      <img
        v-if="modelFlag && vip > 0"
        :src="viewFlag ? uiList[uitype][3] : uiList[uitype][4]"
        @click="viewFlag = !viewFlag"
      />
      <img :src="uiList[uitype][2]" @click="roam1" v-if="vip > 0 && id != 1975" />

      <a href="https://m.yangshipin.cn/static/x/l000064owzq/index.html" target="_blank">
        <img src="../assets/img/addicon1.png" v-if="id == 1975" />
      </a>

      <img
        src="../assets/img/addicon2.png"
        v-if="id == 1975"
        @click="shareFlag1 = true"
      />
    </div>

    <!-- <div class="bottom" v-if="vip == 0">
      <img src="../assets/img/logo2.png" />
      <p>元一畅想提供技术支持</p>
    </div> -->

    <a href="https://mvpmeta.com/?zp" target="_blank">
      <div class="bottom" v-if="vip == 0">
        <img src="../assets/img/logo.png" />
        <p>元居科技提供技术支持</p>
      </div>
    </a>

    <audio :src="voice" id="music" loop />11
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="shadow" v-if="shareFlag">
        <div class="qrCode" v-show="id != 1975">
          <i class="el-icon-close" @click="shareFlag = false"></i>
          <h1>微信小程序二维码</h1>

          <img :src="pic" />
        </div>

        <div id="qrCode" ref="qrCode" v-show="id != 1975">
          <i class="el-icon-close" @click="shareFlag = false"></i>
          <h1>扫描二维码分享作品</h1>
        </div>

        <div v-show="id == 1975" style="text-align: center">
          <img
            src="../assets/img/share.png"
            class="shareImg"
            @click="shareFlag = false"
          />

          <h3 class="h3" v-if="flag1975">点击保存到手机相册</h3>
          <img
            class="shareIcon"
            src="../assets/img/download.png"
            @click="downLoad()"
            v-if="flag1975"
          />
        </div>
      </div>
    </transition>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="shadow" v-if="shareFlag1">
        <img src="../assets/img/rule.png" class="shareImg" @click="shareFlag1 = false" />
      </div>
    </transition>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="commentBox" v-if="comment1Flag">
        <div>
          <h3>评论</h3>
          <!-- <el-checkbox v-model="checked">查看评论</el-checkbox> -->
        </div>

        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="textarea"
          maxlength="30"
          show-word-limit
        ></el-input>
        <div class="line">
          <el-button type="primary" @click="toComments">发表</el-button>
          <el-button @click="comment1Flag = false">取消</el-button>
        </div>
      </div>
    </transition>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-if="checked">
        <p
          class="list1"
          v-for="(item, index) in comment"
          :key="index"
          :style="'left:' + item.left + '%;top:' + item.right + '%'"
        >
          {{ item.text }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import Bus from "../../common/js/bus";
import QRCode from "qrcodejs2";
import QS from "qs";
import {
  get_scene_interacts,
  get_solo_media,
  set_scene_thumbs,
  set_scene_comment,
  qrcode,
  statistics,
} from "../assets/api/index";
export default {
  props: ["obj", "vip", "statistics"],
  data() {
    return {
      viewList: [],
      title: "o2vr三维展厅",
      flag: false,
      value: 999,
      roomFlag: false,
      timmer: "",
      voice: "",
      voiceFlag: false,
      thumbs: 0,
      comment: [],
      thumbsFlag: false,
      viewFlag: false,
      modelFlag: false,
      likeFlag: false,
      commentFlag: false,
      comment1Flag: false,
      addFlag: false,
      id: "",
      shareFlag: false,
      textarea: "",
      checked: false,
      mobile: false,
      scrollX: 0,
      popular: 0,
      uiList: [
        [
          require("../../showroom/assets/icon/icon_1_1.png"),
          require("../../showroom/assets/icon/icon_1_2.png"),
          require("../../showroom/assets/icon/icon_1_3.png"),
          require("../../showroom/assets/icon/icon_1_4.png"),
          require("../../showroom/assets/icon/icon_1_5.png"),
          require("../../showroom/assets/icon/icon_1_6.png"),
          require("../../showroom/assets/icon/icon_1_7.png"),
          require("../../showroom/assets/icon/icon_1_8.png"),
          require("../../showroom/assets/icon/icon_1_9.png"),
          require("../../showroom/assets/icon/icon_1_10.png"),
          require("../../showroom/assets/icon/icon_1_2_2.png"),
          require("../../showroom/assets/icon/icon_1_7_2.png"),
        ],
        [
          require("../../showroom/assets/icon/icon_2_1.png"),
          require("../../showroom/assets/icon/icon_2_2.png"),
          require("../../showroom/assets/icon/icon_2_3.png"),
          require("../../showroom/assets/icon/icon_2_4.png"),
          require("../../showroom/assets/icon/icon_2_5.png"),
          require("../../showroom/assets/icon/icon_2_6.png"),
          require("../../showroom/assets/icon/icon_2_7.png"),
          require("../../showroom/assets/icon/icon_2_8.png"),
          require("../../showroom/assets/icon/icon_2_9.png"),
          require("../../showroom/assets/icon/icon_2_10.png"),
          require("../../showroom/assets/icon/icon_2_2_2.png"),
          require("../../showroom/assets/icon/icon_2_7_2.png"),
        ],
        [
          require("../../showroom/assets/icon/icon_3_1.png"),
          require("../../showroom/assets/icon/icon_3_2.png"),
          require("../../showroom/assets/icon/icon_3_3.png"),
          require("../../showroom/assets/icon/icon_3_4.png"),
          require("../../showroom/assets/icon/icon_3_5.png"),
          require("../../showroom/assets/icon/icon_3_6.png"),
          require("../../showroom/assets/icon/icon_3_7.png"),
          require("../../showroom/assets/icon/icon_3_8.png"),
          require("../../showroom/assets/icon/icon_3_9.png"),
          require("../../showroom/assets/icon/icon_3_10.png"),
          require("../../showroom/assets/icon/icon_3_2_2.png"),
          require("../../showroom/assets/icon/icon_3_7_2.png"),
        ],
        [
          require("../../showroom/assets/icon/icon_4_1.png"),
          require("../../showroom/assets/icon/icon_4_2.png"),
          require("../../showroom/assets/icon/icon_4_3.png"),
          require("../../showroom/assets/icon/icon_4_4.png"),
          require("../../showroom/assets/icon/icon_4_5.png"),
          require("../../showroom/assets/icon/icon_4_6.png"),
          require("../../showroom/assets/icon/icon_4_7.png"),
          require("../../showroom/assets/icon/icon_4_8.png"),
          require("../../showroom/assets/icon/icon_4_9.png"),
          require("../../showroom/assets/icon/icon_4_10.png"),
          require("../../showroom/assets/icon/icon_4_2_2.png"),
          require("../../showroom/assets/icon/icon_4_7_2.png"),
        ],
      ],
      uitype: 0,
      shareFlag1: false,
      flag1975: false,
      pic: "",
      guideFlag: false,
    };
  },
  methods: {
    openComment() {
      this.comment1Flag = true;
      this.flag = false;
    },
    bindQRCode: function () {
      console.log(this.$refs.qrCode);
      new QRCode(this.$refs.qrCode, {
        text: window.location.href,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    async toComments() {
      console.log(this.id);
      if (!this.textarea) {
        this.$notify({
          title: "警告",
          message: "您的评论为空",
          type: "warning",
        });
        return;
      }

      this.comment.push({
        text: this.textarea,
        left: this.random(10, 90),
        right: this.random(10, 90),
      });
      set_scene_comment({
        id: this.id,
        comment: JSON.stringify(this.comment),
      }).then((res) => {
        if (res.code == 0) {
          this.$notify({
            title: "成功",
            message: "评论成功",
            type: "success",
          });

          this.textarea = "";
        }
      });
    },
    toView(e, b) {
      console.log(e.view, "转移视角");
      this.title = e.name;
      this.value = b;
      console.log(this.value);

      document.o2.toView(e.view);
    },
    see() {
      console.log("鸟瞰");
    },
    roam() {
      this.guideFlag = !this.guideFlag;
      document.showroom.active_guide(this.guideFlag);
      this.flag = false;
    },
    roam1() {
      document.showroom.play_record_camera();
    },
    full() {
      function launchFullscreen(element) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }

      launchFullscreen(document.documentElement);
    },
    getNum() {
      get_scene_interacts({ id: this.id }).then((res) => {
        this.thumbs = res.thumbs ? res.thumbs : 0;
        // this.comment = res.comment ? JSON.parse(res.comment) : [];
        console.log(this.comment);

        this.popular = res.popular ? res.popular : 0;
      });
    },

    async addNum() {
      // await this.getNum();
      this.addFlag = !this.addFlag;
      this.thumbs++;
      set_scene_thumbs({ id: this.id, thumbs: this.thumbs }).then((res) => {
        // this.thumbs = res.thumbs;
      });
    },

    random(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    right() {
      console.log(-1 * this.scrollX, 100 * this.viewList.length);
      if (-1 * this.scrollX > 100 * (this.viewList.length - 6)) return;
      this.scrollX -= 100;
    },
    left() {
      if (this.scrollX == 0) return;
      this.scrollX += 100;
    },

    readTextFile(file, callback) {
      var rawFile = new XMLHttpRequest();
      rawFile.overrideMimeType("application/json");
      rawFile.open("GET", file, true);
      rawFile.onreadystatechange = function () {
        if (rawFile.readyState === 4 && rawFile.status == "200") {
          callback(rawFile.responseText);
        }
      };
      rawFile.send(null);
    },

    downLoad() {
      var that = this;
      var image = new Image();
      image.src =
        "https://o2res.oss-cn-beijing.aliyuncs.com/client/%E6%98%9F%E6%98%9F%E7%9A%84%E4%B8%96%E7%95%8C/%E6%98%9F%E6%98%9F.png?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001648539157&Signature=bV%2BtcVuXtNPPxL5xvo%2BvThJu5pM%3D";
      image.onload = function () {
        var base64 = getBase64Image(image);
        var jsbridge = window["cctvh5-jsbridge"].default;
        jsbridge.ready().then((bridge) => {
          console.log(bridge);

          if (bridge) {
            that.flag1975 = true;
            jsbridge
              .saveImage({
                imgbase64String: base64,
              })
              .then(function (rs) {
                assert(rs.errCode === 0);
                done();
              });
          }
        });
      };

      function getBase64Image(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
        var dataURL = canvas.toDataURL("image/" + ext);
        return dataURL;
      }
    },
  },
  mounted() {
    var that = this;

    this.readTextFile("public/packge.json", function (text) {
      console.log(text);

      var data = JSON.parse(text);
      console.log(data);
      that.id = data.id;
      // const query = QS.parse(location.hash.substring(3));
      // that.id = query.id;
      var audio = document.getElementById("music");
      statistics({
        id: that.id,
      }).then((res) => {
        console.log(res);
      });
      function audioAutoPlay() {
        audio.play();
      }
      document.addEventListener("click", function () {
        if (that.voiceFlag) {
          audioAutoPlay();
        }
      });
      document.addEventListener("touchstart", function () {
        console.log("start");
        if (that.voiceFlag) {
          audioAutoPlay();
        }
      });
      document.onkeydown = function (event) {
        if (event && that.voiceFlag) {
          audioAutoPlay();
        }
      };
      Bus.$on("music", (e) => {
        that.voiceFlag = e;
      });

      that.getNum();
      console.log(that.id);
      qrcode({ sid: that.id }).then((res) => {
        this.pic = res.data.url;
      });
    });
  },

  watch: {
    obj(val) {
      console.log(val);
      if (val.viewList) this.viewList = val.viewList;
      console.log(val);
      if (val.title) this.title = val.title;
      if (val.voice) {
        get_solo_media({ id: val.voice.id }).then((e) => {
          this.voice = e.data[0].url;
          console.log(e);
        });
      }

      if (val.voiceFlag) this.voiceFlag = val.voiceFlag;
      if (val.viewFlag) this.viewFlag = val.viewFlag;
      if (val.modelFlag) this.modelFlag = val.modelFlag;
      if (val.commentFlag) this.commentFlag = val.commentFlag;
      if (val.likeFlag) this.likeFlag = val.likeFlag;
      if (val.uitype) this.uitype = val.uitype - 1;
    },

    roomFlag(val) {
      if (val == true) {
        this.timmer = setInterval(() => {
          document.getElementById("roam").style = "background-color: red";
          setTimeout(() => {
            document.getElementById("roam").style = "";
          }, 250);
          console.log(document.getElementById("roam"));
        }, 500);
      } else {
        console.log("close");
        clearInterval(this.timmer);
      }
    },

    viewFlag(val) {
      document.o2.setViewMode(!val);
    },
    shareFlag(val) {
      if (val) {
        this.$nextTick(function () {
          this.bindQRCode();
        });
      }
    },
    voiceFlag(val) {
      var audio = document.getElementById("music");
      if (val == true) {
        audio.play();
      } else {
        audio.pause();
      }
    },

    async checked(val) {
      if (val) {
        await this.getNum();
      }
    },
  },
  created() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.mobile = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.scroll {
  width: 100%;
  overflow-x: scroll;
  height: 100px;
  position: absolute;
  bottom: 82px;
  background: rgba($color: #000, $alpha: 0.4);
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
}
.viewList {
  height: 100px;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  transition: 0.5s;

  .list {
    width: 80px;
    height: 80px;
    background: #595959;
    border: 1px solid transparent;
    position: relative;
    margin: 0 10px;
    text-align: center;
    overflow: hidden;
    border-radius: 4px;
    cursor: pointer;
    img {
      height: 100%;

      margin-left: -20px;

      cursor: pointer;
    }
    .font {
      text-align: center;
      font-size: 12px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba($color: #000000, $alpha: 0.5);
      position: absolute;
      bottom: 0;
      width: 100%;
      color: white;
    }
  }
  .color {
    border: 1px solid white;
    transition: 0.5s;
  }
}
.title {
  background: rgba($color: #1d345b, $alpha: 0.67);
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #17e0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: auto;
  top: 60px;
  padding: 10px 20px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.down {
  width: 50px;

  position: absolute;
  bottom: 30px;

  right: 20px;
  margin: auto;
  cursor: pointer;
  transform: rotate(0deg);
  transition: 0.5s;
}
.trans {
  transform: rotate(180deg);
}
.niaokan {
  padding: 5px 15px;
  background-color: rgba($color: #1d345b, $alpha: 0.67);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #fffefe;
  position: absolute;
  top: 18px;
  right: 40px;
  cursor: pointer;
  border-radius: 3px;
  transform: 0.2s;
  img {
    width: 20px;
    height: 20px;
    margin-right: 9px;
    margin-top: 3px;
  }
}
.roam {
  top: 60px;
}
.functionB {
  width: 198px;
  height: 50px;
  background: rgba($color: #fff, $alpha: 0.8);

  border-radius: 25px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 16px;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  display: flex;
  .item {
    // width: 50px;
    text-align: center;
    opacity: 0.65;
    font-size: 12px;
    color: #000;
    p {
      margin-top: -5px;
    }
  }
}
.fun::-webkit-scrollbar {
  display: none;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.functionBottom {
  width: 150px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  img {
    width: 34px;
    height: 34px;
    cursor: pointer;
  }
  p {
    color: white;
    position: absolute;

    margin: auto;
    bottom: -15px;
    font-size: 12px;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.functionTop {
  position: absolute;
  top: 20px;
  width: 34px;
  right: 20px;
  z-index: 100;
  img {
    width: 34px;
    height: 34px;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 50%;
  }
}
.title1 {
  width: 232px;
  height: 48px;
  background: rgba($color: #000, $alpha: 0.5);

  border-radius: 24px;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 54px;
  color: #ffffff;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  justify-content: center;
  align-items: center;
  display: flex;
}
.bottom {
  width: 220px;
  height: 34px;
  background: rgba(0, 0, 0, 0.39);
  border-radius: 17px;
  left: 20px;
  bottom: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 20px;
    margin-right: 10px;
  }
  p {
    font-size: 14px;

    color: #ffffff;

    opacity: 0.85;
  }
}
.shadow {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
  .qrCode,
  #qrCode {
    padding: 30px 60px;
    background-color: white;
    position: relative;
    h1 {
      font-size: 20px;
      margin-bottom: 10px;
      text-align: center;
    }
    img {
      width: 200px;
      height: 200px;
    }
  }

  ::v-deep.el-icon-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}
.commentBox {
  width: 350px;
  background: rgba(0, 0, 0, 0.39);
  padding: 15px;
  border-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 80px;
  ::v-deep .el-checkbox {
    color: #fff;
  }
  h3 {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 20px;
    display: inline-block;
    margin-right: 20px;
  }
  .line {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    ::v-deep .el-button {
      width: 30%;
    }
  }
}
.list1 {
  position: absolute;

  padding: 5px 20px;
  background: rgba(0, 0, 0, 0.39);
  border-radius: 5px;
  color: white;
  font-size: 12px;
}
.pcscroll {
  width: 620px;
  overflow-x: scroll;
}

.pcscroll::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.log {
  width: 80px;
  height: fit-content;
  cursor: pointer;
}
.shareImg {
  width: 300px;
  cursor: pointer;
}
.shareIcon {
  width: 40px;
  cursor: pointer;
}
.h3 {
  margin: 10px;
  color: #fff;
}
.popular {
  position: absolute;
  left: 0px;
  top: 20px;
  color: #fff;
  right: 0;
  margin: auto;
  width: 200px;
}
@media screen and (max-width: 450px) {
  .functionB {
    transform: scale(0.8);
  }
  .functionBottom {
    transform: scale(0.7);
    right: -20px;
    bottom: 25px;
  }
}
</style>

<template>
  <div class="login" v-if="flag1">
    <div v-if="flag" @click="boxFlag = true">登录</div>
    <div v-else style="text-align: right">
      <div style="margin-bottom: 25px">
        欢迎{{ name }} 当前在线人数：{{ list.length }}人
      </div>
      <div v-for="(item, index) in leftList" :key="index" class="func">
        <img :src="item.flag ? item.activeimg : item.img" @click="change(index)" />
      </div>

      <Chat />
    </div>
    <div class="box" v-if="boxFlag">
      <div class="line">
        <p>名称：</p>
        <el-input v-model="name" placeholder="请输入名称"></el-input>
      </div>

      <div class="line">
        <p>角色：</p>
        <div class="roleBox">
          <div v-for="(item, index) in roleList" :key="index" @click="roleValue = index">
            <img :src="item" :class="['avatar', index == roleValue ? 'active' : '']" />
          </div>
        </div>
      </div>
      <div class="line1">
        <el-button type="primary" @click="loginIn">确认</el-button>
        <el-button
          @click="
            boxFlag = false;
            roleValue = 0;
          "
          >取消</el-button
        >
      </div>
    </div>

    <!-- <div id="local_stream" autoplay style="width: 0"></div> -->
    <!-- <video id="local_stream" autoplay></video> -->
  </div>
</template>
<script>
import Bus from "../../common/js/bus";
import Chat from "./chat.vue";
import QS from "qs";

import { rtc_timer, search_time, update_time } from "../assets/api/index";
export default {
  props: ["flag1"],
  components: { Chat },
  data() {
    return {
      flag: true,
      num: 1,
      name: "",
      roleList: [
        require("../assets/img/role1.jpg"),
        require("../assets/img/role2.jpg"),
        require("../assets/img/role3.png"),
        require("../assets/img/role4.png"),
        require("../assets/img/role5.jpg"),
        require("../assets/img/role6.jpg"),
      ],

      leftList: [
        // {
        //   img: require("../assets/img/icon_lt_g.png"),
        //   activeimg: require("../assets/img/icon_lt_k.png"),
        //   flag: false,
        // },
        {
          img: require("../assets/img/icon_yu_g.png"),
          activeimg: require("../assets/img/icon_yu_k.png"),
          flag: false,
        },
        {
          img: require("../assets/img/icon_qp_g.png"),
          activeimg: require("../assets/img/icon_qp_k.png"),
          flag: false,
        },
      ],

      roleValue: 0,
      boxFlag: false,
      value: false,
      value1: false,
      list: [],

      adminFlag: false,
      timmer: "",
      loginFlag: false,
      room_id: 1,
      voiceFlag: false,
      videoFlag: false,

      abegintime: "", //音频开始时间
      vbegintime: "", //视频开始时间
      timmer1: "", //计时开始
      atime: 0,
      vtime: 0,
    };
  },
  methods: {
    loginIn() {
      if (!this.name) {
        this.$message({
          type: "error",
          message: "请输入昵称",
        });
        return;
      }
      if (document.o2.login) document.o2.login(this.name, this.room_id, this.roleValue);
      if (this.name == "admin_") {
        this.adminFlag = true;
      }

      this.$message({
        type: "success",
        message: "登陆成功，你的昵称是: " + this.name,
      });

      this.boxFlag = false;
    },

    change(e) {
      if (e == 0) {
        this.leftList[0].flag = !this.leftList[0].flag;

        Bus.$emit("mute", this.leftList[0].flag);
      } else {
        this.leftList[1].flag = !this.leftList[1].flag;
        Bus.$emit("vmute", [this.leftList[1].flag, this.name]);
        this.$chat.send(`vmute,${this.name},${this.leftList[1].flag}`);
      }
    },
    //登陆语音
    async conne() {
      var num = String(this.name);

      this.$client.login(num, Number(this.room_id));

      this.timmer = setInterval(() => {
        if (this.loginFlag == false) {
          this.loginOut();
        } else {
          this.loginFlag = false;
        }
      }, 300000);
    },

    //关闭麦克风
    closeAudio() {
      this.audioFlag = !this.audioFlag;
      Bus.$emit("mute", this.audioFlag);
    },

    online() {
      // console.log("用户在操作");
      this.loginFlag = true;
    },

    //退出登录
    loginOut() {
      console.log("退出登录");
      this.$client.loginOut();
      this.$message.console.error("您已经退出登录");
      setTimeout(() => {
        location.reload();
      }, 500);
    },
    videoTime() {
      var endtime = new Date().getTime();
      this.saveTime(endtime, "video");
    },

    audioTime() {
      var endtime = new Date().getTime();
      this.saveTime(endtime, "voice");
    },

    saveTime(endtime, type) {
      var list = this.$client.client.getRemoteMutedState();
      var videoList = [];
      var audioList = [];
      var num = 0;
      var time = 0;
      list.forEach((e) => {
        if (e.audioMuted == false) {
          audioList.push(e);
        }
        if (e.videoMuted == false) {
          videoList.push(e);
        }
      });
      if (type == "video") {
        var num = videoList.length;
        this.vtime += 0.5 * num;
        var time = this.vtime;
      } else {
        var num = audioList.length;
        this.atime += 0.5 * num;
        var time = this.atime;
      }

      rtc_timer({
        sid: document.showroom.room_id,
        name: this.name,
        begin_time: this.abegintime,
        time,
        type,
      }).then((res) => {});
    },
  },
  watch: {
    value(val) {
      Bus.$emit("admin_mode", val);
    },

    voiceFlag(val) {
      // if (val == true) {
      // } else if (val == false) {
      //   var endtime = new Date().getTime();
      //   clearInterval(this.timmer1);
      //   this.saveTime(endtime, "voice");
      //   // console.log(`开始时间${this.abegintime}, 结束时间${endtime}`);
      // }
      Bus.$emit("mute", val);
    },
    // leftList: {
    //   handler(newVal) {
    //     Bus.$emit("mute", newVal[1].flag);

    //     Bus.$emit("vmute", [newVal[2].flag, this.name]);

    //     this.$chat.send(`vmute,${this.name},${newVal[2].flag}`);
    //   },
    //   deep: true,
    // },

    videoFlag(val) {
      // if (val == true) {
      //   this.vbegintime = new Date().getTime();
      //   this.timmer1 = setInterval(() => {
      //     this.audioTime();
      //   }, 10000);
      // } else if (val == false) {
      //   var endtime = new Date().getTime();
      //   clearInterval(this.timmer1);
      //   this.saveTime(endtime, "video");
      // }
    },
  },
  created() {
    document.addEventListener("click", this.online, false);
    document.addEventListener("keydown", this.online, false);

    Bus.$on("num", (target) => {
      this.num += target;
    });
    Bus.$on("player", (target) => {
      this.list = target.players;
    });

    Bus.$on("playerInited", () => {
      this.conne();
      this.flag = false;

      this.roleValue = 0;

      this.abegintime = new Date().getTime();
      this.timmer1 = setInterval(() => {
        this.audioTime();
        this.videoTime();

        search_time({ sid: document.showroom.room_id }).then((res) => {
          var voice_time = res.voice_time;
          var video_time = res.video_time;
          update_time({
            id: document.showroom.room_id,
            voice_time,
            video_time,
          }).then((res) => {
            console.log(res);
            if (res.data.video_time <= 0) {
              this.$message.error("您的视频时长已用完,请前往官网续费");
            }
            if (res.data.voice_time <= 0) {
              this.$message.error("您的语音时长已用完,请前往官网续费");
            }
          });
        });
      }, 30000);
    });
  },

  destroyed() {
    if (this.voiceFlag) {
      alert(`开始时间${this.abegintime}, 结束时间${endtime}`);
    }
  },

  mounted() {
    const query = QS.parse(location.hash.substring(3));
    this.room_id = query.id;
    // this.room_id = 3303;
  },
  destroyed() {
    document.removeEventListener("click", this.online, false);
    document.removeEventListener("keydown", this.online, false);
  },
};
</script>

<style lang="scss" scoped>
.login {
  position: absolute;
  top: 20px;
  color: #fff;
  left: 40px;
  z-index: 99;
  font-family: Source Han Sans TC;
  cursor: pointer;

  ::v-deep.el-switch__label {
    color: white;
  }
  ::v-deep.el-switch__label.is-active {
    color: #409eff;
  }
}
.box {
  width: 350px;
  height: 280px;
  background: #ffffff;
  box-shadow: 1px 3px 17px 1px rgba(66, 84, 142, 0.31);
  border-radius: 8px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  .line1 {
    // float: right;
    // margin-right: 20px;
    // margin-top: 20px;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .line {
    display: flex;
    align-items: center;
    margin: 10px 0;
    p {
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #666;
      margin-left: 20px;
    }
    .el-input {
      width: 200px;
      margin: 15px;
    }
    .roleBox {
      display: flex;
      width: 250px;
      flex-wrap: wrap;
      .avatar {
        width: 50px;
        height: 50px;
        border: 2px solid #bebebe;
        border-radius: 50%;
        margin-left: 20px;
      }
      .active {
        border: 2px solid #409eff;
        transition: 0.5s;
      }
    }
  }
}
.audioList {
  width: 190px;
  height: 458px;
  background: rgba($color: #fff, $alpha: 0.5);
  box-shadow: 1px 3px 17px 1px rgba(66, 84, 142, 0.31);
  border-radius: 8px;
  position: absolute;
  right: 0px;
  top: 160px;

  .list:first-of-type {
    margin-top: 10px;
  }
  .list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    p {
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #333333;
      width: 100px;
      text-align: left;
    }
    .img {
      width: 14px;
      height: 21px;
      // background-image: url("../assets/img/voice.png");
      position: relative;
      .black {
        width: 8px;
        height: 14px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        background-color: black;
        border-radius: 4px;
        overflow: hidden;
        top: 0;
        position: relative;
        .green {
          width: 100%;
          background: #00ff00;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
}

.func {
  text-align: left;
  margin-top: 10px;
}

// @media screen and (max-width: 450px) {
//   .login {
//     font-size: 12px;
//     right: 20px;
//     top: 17px;
//   }
// }
</style>

import axios from 'axios'
import TRTC from 'trtc-js-sdk'
import Bus from './bus'

// import * as LibGenerateTestUserSig from './lib-generate-test-usersig.min.js'
class o2vrClient {
  constructor() {
    //凭证
    this.config = {
      mode: 'rtc',
      sdkAppId: '',
      userId: '',
      usersig: ''
    }
    //客户端
    this.client = ''
    this.list = []
    this.audioflag = false
    this.videoflag = false
  }

  //登录client
  async login(userid, roomId) {
    console.log(userid)
    //获取签证
    // const SDKAPPID = 1400601861;
    // const EXPIRETIME = 604800;
    // const SECRETKEY = 'fe1ea13d889fc1a987cc2164bca5818e172dd777659827521606da9418a8c472';
    // const generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME)
    // const userSig = generator.genTestUserSig(userID)
    // this.config.userId = userid
    // this.config.userSig = generator.genTestUserSig(userid)
    Bus.$on('mute', (target) => {
      if (!this.videoflag & !this.audioflag) {
        this.client.publish(localStream)
        Bus.$emit('localFlag', true)
      }
      this.audioflag = target
      if (target) {
        localStream.unmuteAudio()
      } else {
        localStream.muteAudio()
      }
      console.log(this.videoflag, this.audioflag)
      if (!this.videoflag & !this.audioflag) {
        this.client.unpublish(localStream)
        Bus.$emit('localFlag', false)
      }
    })

    Bus.$on('vmute', (target) => {
      if (!this.videoflag & !this.audioflag) {
        this.client.publish(localStream)
        Bus.$emit('localFlag', true)
      }
      this.videoflag = target[0]
      if (target[0]) {
        Bus.$emit('spliu', localStream)
        localStream.unmuteVideo()
      } else {
        localStream.muteVideo()
      }

      if (!this.videoflag & !this.audioflag) {
        this.client.unpublish(localStream)
        Bus.$emit('localFlag', false)
      }
    })
    await this.get(userid).then((res) => {
      this.config.userId = userid
      this.config.userSig = res.sig
      this.config.sdkAppId = res.appid
    })
    //建立客户端
    this.client = await TRTC.createClient(this.config)
    let remoteStreamList = []
    //订阅远方流
    await this.client.on('stream-added', (event) => {
      const remoteStream = event.stream
      console.log('远端流增加: ' + remoteStream.getId())
      this.client.subscribe(remoteStream)
    })

    await this.client.on('stream-removed', ({ stream: remoteStream }) => {
      Bus.$emit('removelist', remoteStream)
    })

    // 播放远端流
    await this.client.on('stream-subscribed', (event) => {
      const remoteStream = event.stream
      console.log('远端流订阅成功：' + remoteStream.getId())

      Bus.$emit('addlist', remoteStream)
    })

    // 开始获取流音量
    let getAudioLevelTimer = -1
    var that = this
    function startGetAudioLevel() {
      getAudioLevelTimer = setAnimationFrame(() => {
        var list = that.client.getRemoteMutedState()
        Bus.$emit('listChange', list)
      })
    }
    // 停止获取流音量
    function stopGetAudioLevel() {
      if (getAudioLevelTimer !== -1) {
        clearAnimationFrame(getAudioLevelTimer)
        getAudioLevelTimer = -1
      }
    }

    // 设置AnimationFrame
    function setAnimationFrame(render) {
      // 计时器
      let timer = {}
      function animeLoop() {
        render()
        timer.id = requestAnimationFrame(animeLoop)
      }
      animeLoop()
      return timer
    }
    // 清除AnimationFrame
    function clearAnimationFrame(timer) {
      cancelAnimationFrame(timer.id)
    }
    //进入房间
    await this.client
      .join({ roomId })
      .catch((error) => {
        console.error('进房失败 ' + error)
      })
      .then(() => {
        console.log('进房成功')
        startGetAudioLevel()
      })
    //await this.shareScreen(roomId);
    var localStream
    await TRTC.getCameras().then((res) => {
      console.log(res)
      if (res.length != 0) {
        console.log('拥有摄像头', res[0])
        localStream = TRTC.createStream({
          userId: this.config.userId,
          audio: true,
          video: true
        })
      } else {
        console.log('没有摄像头开启语音')
        localStream = TRTC.createStream({
          userId: this.config.userId,
          audio: true,
          video: false
        })

        // localStream.muteAudio();
      }
    })
    //创建流

    //初始化本地音视频流。

    await localStream
      .initialize()
      .catch((error) => {
        console.log('初始化本地流失败 ' + error)
        return
      })
      .then(() => {
        console.log('初始化本地流成功')
        localStream.play('local_stream')
        console.log(document.getElementById('local_stream'))
        localStream.muteAudio()
        localStream.muteVideo()
      })
  }

  async loginOut() {
    this.client
      .leave()
      .then(() => {
        // 退房成功，可再次调用client.join重新进房开启新的通话。
        console.log('退房成功')
        this.list = []
      })
      .catch((error) => {
        console.error('退房失败 ' + error)
        // 错误不可恢复，需要刷新页面。
      })
  }

  test() {
    this.client.leave().then(() => {
      this.client.join({ roomId: 1000 }).then(() => {
        //this.client.roomId
        console.log('重新加入1000频道', this.client)
      })
    })
  }

  //获取签证
  get(userid) {
    return new Promise((resolve, reject) => {
      axios
        .get('https://api.wh12345.net/trtc/usersig.php?', {
          params: {
            userid,
            appname: 'O2VR'
          }
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.data)
        })
    })
  }
}

export { o2vrClient }

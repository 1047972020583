<template>
  <div class="circle" v-if="touch" id="circle">
    <img
      src="../assets/img/btn.png"
      @touchstart="start"
      :style="'left:' + left + 'px;top:' + top + 'px'"
    />
  </div>
</template>

<script>
import Bus from "../../common/js/bus";
export default {
  props: ["touch"],
  data() {
    return {
      left: 0,
      top: 0,
      position: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    start(e) {
      this.position.x = e.touches[0].clientX;
      this.position.y = e.touches[0].clientY;
      document.getElementById("circle").addEventListener("touchmove", this.move);
      document.getElementById("circle").addEventListener("touchend", this.end);
    },
    move(e) {
      e.preventDefault();
      this.position.x = e.touches[0].clientX;
      this.position.y = e.touches[0].clientY;
    },
    end() {
      console.log("end");

      document.getElementById("circle").removeEventListener("touchmove", this.move);

      this.left = 0;
      this.top = 0;
      this.position = {
        x: 0,
        y: 0,
      };

      Bus.$emit("key", "all");
    },
  },
  created() {},
  watch: {
    "position.x": {
      deep: true,
      handler: function (newV, oldV) {
        if (newV != 0 && oldV) {
          var x = newV - oldV;

          if (this.left > 65) this.left = 65;
          else if (this.left < -75) this.left = -75;
          else if (x) this.left += x;
        }

        console.log(this.left, "当前到中心点的x轴距离");
        console.log(x);
        Bus.$emit("yaogan", this.left, this.top);
        // if (x > 1) {
        //   Bus.$emit("key", "d");
        // } else if (x < -1) {
        //   Bus.$emit("key", "a");
        // }
      },
    },

    "position.y": {
      deep: true,
      handler: function (newV, oldV) {
        if (newV != 0 && oldV) {
          var y = newV - oldV;

          if (this.top > 75) this.top = 75;
          else if (this.top < -75) this.top = -75;
          else if (y) this.top += y;
          console.log(this.top, "当前到中心点的y轴距离");
          Bus.$emit("yaogan", this.left, this.top);
          // if (y > 1) {
          //   Bus.$emit("key", "s");
          // } else if (y < -1) {
          //   Bus.$emit("key", "w");
          // }
        }
      },
    },
  },
  mounted() {
    // document.getElementById("circle").addEventListener(
    //   "touchmove",
    //   function (e) {
    //     e.preventDefault();
    //   },
    //   { passive: false }
    // );
  },
};
</script>

<style scoped lang="scss">
.circle {
  width: 100px;
  height: 100px;
  border: 3px solid rgba(224, 224, 224, 0.3);
  border-radius: 50%;
  background: rgba(209, 209, 209, 0.3);
  position: absolute;
  left: -1px;
  bottom: 8px;
  transform: scale(0.8);

  img {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: 0.1s;
  }
}
</style>
